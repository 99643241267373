<template>
  <div class="clientsList">

    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div class="">
          <label for="">Фильтрация по клиенту</label>
          <a-select
              mode="multiple"
              placeholder="Выберите клиента"
              v-model="dataFilter.clients"
              style="width: 230px"
              optionLabelProp="label"
              optionFilterProp="label"
              @change="applyFilter"
              @search="searchCountry"
            >
            <a-select-option v-for="client in clients" :key="client.id" :value="client.id" :label="client.name">
              {{client.name}}
            </a-select-option>
          </a-select>
        </div>
        <div class="">
          <label for="">Фильтрация по странам</label>
          <a-select
              mode="multiple"
              placeholder="Выберите страну"
              v-model="dataFilter.countries"
              style="width: 230px"
              optionLabelProp="label"
              optionFilterProp="label"
              @change="applyFilter"
              @search="searchCountry"
            >
            <a-select-option v-for="country in countries" :key="country.id" :value="country.id" :label="country.title">
              {{country.title}}
            </a-select-option>
          </a-select>
        </div>
        <div class="">
          <label for="">Фильтрация по областям</label>
          <a-select
              mode="multiple"
              placeholder="Выберите область"
              v-model="dataFilter.regions"
              style="width: 230px"
              optionLabelProp="label"
              optionFilterProp="label"
              @change="applyFilter"
              @search="searchRegion"
            >
            <a-select-option v-for="region in regions" :key="region.id" :value="region.id" :label="region.title">
              {{region.title}}
            </a-select-option>
          </a-select>
        </div>

        <div class="">
          <label for="">Фильтрация по городам</label>
          <a-select
              mode="multiple"
              placeholder="Выберите город"
              v-model="dataFilter.cities"
              style="width: 230px"
              optionLabelProp="label"
              optionFilterProp="label"
              @change="applyFilter"
              @search="searchCity"
            >
            <a-select-option v-for="city in cities" :key="city.id" :value="city.id" :label="city.title">
              {{city.title}}
            </a-select-option>
          </a-select>
        </div>
        <div class="">
          <label for="">Поиск по имени</label>
          <template>
            <a-input placeholder="Имя"
            v-model="dataFilter.name"
            @change="applyFilter"
            />
          </template>
        </div>

      </div>
    </div>

    <mini-loader v-if="load" />
    <div class="card" style="overflow-x: auto; max-width: 100%">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="text-dark">Все адреса</h5>
        <a @click="createItem">
          <span class=" p-1">
            <button type="button" class="btn btn-success" >Добавить
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 20 20">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>

          </span>
        </a>
      </div>
      <table class="table table-striped" id="addressesList">
        <thead>
          <tr>
            <th scope="col">#</th>
            <td>Клиент</td>
            <td>Имя</td>
            <td>Страна</td>
            <td>Область</td>
            <td>Район</td>
            <td>Адрес</td>
            <td>Телефон</td>
            <th scope="col">Действие</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) in data">
            <th scope="row">{{index+1}}</th>
            <td> <span v-if="value.client" style="color: #5355D3; cursor: pointer">{{value.client.name}}</span> <span v-else></span> </td>
            <td> {{value.name}} </td>
            <td> <span v-if="value.country">{{value.country.title}}</span> <span v-else></span> </td>
            <td> <span v-if="value.region">{{value.region.title}}</span> <span v-else></span> </td>
            <td> <span v-if="value.city">{{value.city.title}}</span> <span v-else></span> </td>
            <td> {{value.address}} </td>
            <td> {{value.phone}} </td>
            <td style="display: flex">
              <a  @click="showItem(value)">
                <span class="border rounded border-primary m-1 p-2 text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                  </svg>
                </span>
              </a>
              <a @click="editItem(value)">
                <span class="border rounded border-success m-1 p-2 text-success">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>
                </span>
              </a>
              <a @click="deleteItem(index, value)">
                <span class="border rounded border-danger m-1 p-2 text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="m-10 text-dark text-center" style="border: 1px solid #DFE1E4; padding: 10px">1 - {{data.length}} данных  из {{totalResults}}</p>
    </div>

    <!-- Show modal -->
    <a-modal v-model="visibleShowModal" footer="">

    </a-modal>

    <!-- Create modal -->
    <a-modal v-model="visibleCreateModal" title="Добавить адреса"  footer="" class="address-create">

      <div class="d-flex justify-content-between">
        <button class="btn btn-info w-100"  @click="function(){visibleMap = !visibleMap}">
          <div>
            Карта
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin-map" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z"/>
              <path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"/>
            </svg>
          </div>
           <!-- Карта -->
        </button>
      </div>
      <span class="mb-3"  style="color:red" v-if="validateErrors.points">location is invalide</span> <span v-else></span>

      <div class="mb-3">
        <label for="">Клиент <span style="color: #e55353">*</span></label>
        <a-select
            placeholder="Тип здания"
            style="width: 100%"
            v-model="newItem.user_id"
            optionLabelProp="label"
            optionFilterProp="label"
            :default-value="'residential'"
            :allowClear="true"
            :class="{'is-invalid': validateErrors.user_id}"
          >
          <a-select-option v-for="client in clients" :key="client.id" :value="client.id" :label="client.name">
            {{client.name}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.user_id"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Имя отправителя <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Имя отправителя" v-model="newItem.name" :class="{'is-invalid': validateErrors.name}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.name"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Страна отправителя <span style="color: #e55353">*</span></label>
        <a-select
          show-search
          placeholder="Выберите страну"
          option-filter-prop="children"
          style="width: 100%"
          v-model="newItem.country_id"
          :allowClear="true"
          :filter-option="filterOption"
          @search="searchCountries"
          @change="changeCountries"
          :class="{'is-invalid': validateErrors.country_id}"
        >
          <a-select-option v-for="country in countries" :key="country.id" :value="country.id" :label="country.title">
            {{country.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.country_id"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Область отправителя <span style="color: #e55353">*</span></label>
        <a-select
          show-search
          placeholder="Выберите область"
          option-filter-prop="children"
          style="width: 100%"
          v-model="newItem.region_id"
          :allowClear="true"
          :filter-option="filterOption"
          @search="searchRegions"
          @change="changeRegions"
          :class="{'is-invalid': validateErrors.region_id}"
        >
          <a-select-option v-for="region in regions" :key="region.id" :value="region.id" :label="region.title">
            {{region.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.region_id"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Район отправителя <span style="color: #e55353">*</span></label>
        <a-select
          show-search
          placeholder="Выберите район"
          option-filter-prop="children"
          style="width: 100%"
          v-model="newItem.city_id"
          :allowClear="true"
          :filter-option="filterOption"
          @search="searchCities"
          @change="changeCities"
          :class="{'is-invalid': validateErrors.city_id}"
        >
          <a-select-option v-for="city in cities" :key="city.id" :value="city.id" :label="city.title">
            {{city.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.city_id"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Адрес отправителя <span style="color: #e55353">*</span></label>
        <template>
          <a-input v-model="newItem.address" placeholder="Адрес отправителя" :class="{'is-invalid': validateErrors.address}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.address"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Тип здания <span style="color: #e55353">*</span></label>
        <a-select
            placeholder="Тип здания"
            style="width: 100%"
            v-model="newItem.type_of_building"
            optionLabelProp="label"
            optionFilterProp="label"
            :default-value="'residential'"
            :allowClear="true"
            :class="{'is-invalid': validateErrors.type_of_building}"
          >
          <a-select-option v-for="type in typeOfAddress" :key="type.value" :value="type.value" :label="type.title">
            {{type.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.type_of_building"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Ближайший ориентир</label>
        <template>
          <a-input placeholder="Ближайший ориентир" v-model="newItem.reference_point" :class="{'is-invalid': validateErrors.reference_point}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.reference_point"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Главный телефонный номер <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Главный телефонный номер" v-model="newItem.phone" :class="{'is-invalid': validateErrors.phone}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.phone"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Альтернативный тел.номер - 1</label>
        <template>
          <a-input placeholder="Альтернативный тел.номер - 1" v-model="newItem.phone_1" :class="{'is-invalid': validateErrors.phone_1}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.phone_1"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Альтернативный тел.номер - 2</label>
        <template>
          <a-input placeholder="Альтернативный тел.номер - 2" v-model="newItem.phone_2" :class="{'is-invalid': validateErrors.phone_2}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.phone_2"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Электронная почта отправителя</label>
        <template>
          <a-input placeholder="Электронная почта отправителя" v-model="newItem.email" :class="{'is-invalid': validateErrors.email}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.email"> {{value}} </div>
        </template>
      </div>

      <div class="d-flex mt-3">
        <button type="submit" class="ml-auto btn btn-primary" @click="storeItem">Добавить</button>
      </div>
    </a-modal>

    <!-- Edit modal -->
    <a-modal v-model="visibleEditModal" title="Изменить адреса"  footer="">
      <div class="d-flex justify-content-between">
        <button class="btn btn-info w-100"  @click="function(){visibleMapForEdit = !visibleMapForEdit}">
          <div>
            Карта
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin-map" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z"/>
              <path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"/>
            </svg>
          </div>
           <!-- Карта -->
        </button>
      </div>
      <span class="mb-3"  style="color:red" v-if="validateErrors.points">location is invalide</span> <span v-else></span>

      <div class="mb-3">
        <label for="">Клиент <span style="color: #e55353">*</span></label>
        <a-select
            placeholder="Тип здания"
            style="width: 100%"
            v-model="item.user_id"
            optionLabelProp="label"
            optionFilterProp="label"
            :default-value="'residential'"
            :allowClear="true"
            :class="{'is-invalid': validateErrors.user_id}"
          >
          <a-select-option v-for="client in clients" :key="client.id" :value="client.id" :label="client.name">
            {{client.name}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.user_id"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Имя отправителя <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Имя отправителя" v-model="item.name" :class="{'is-invalid': validateErrors.name}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.name"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Страна отправителя <span style="color: #e55353">*</span></label>
        <a-select
          show-search
          placeholder="Выберите страну"
          option-filter-prop="children"
          style="width: 100%"
          v-model="item.country_id"
          :allowClear="true"
          :filter-option="filterOption"
          @search="searchCountries"
          @change="changeCountries"
          :class="{'is-invalid': validateErrors.country_id}"
        >
          <a-select-option v-for="country in countries" :key="country.id" :value="country.id" :label="country.title">
            {{country.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.country_id"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Область отправителя <span style="color: #e55353">*</span></label>
        <a-select
          show-search
          placeholder="Выберите область"
          option-filter-prop="children"
          style="width: 100%"
          v-model="item.region_id"
          :allowClear="true"
          :filter-option="filterOption"
          @search="searchRegions"
          @change="changeRegions"
          :class="{'is-invalid': validateErrors.region_id}"
        >
          <a-select-option v-for="region in regions" :key="region.id" :value="region.id" :label="region.title">
            {{region.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.region_id"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Район отправителя <span style="color: #e55353">*</span></label>
        <a-select
          show-search
          placeholder="Выберите район"
          option-filter-prop="children"
          style="width: 100%"
          v-model="item.city_id"
          :allowClear="true"
          :filter-option="filterOption"
          @search="searchCities"
          @change="changeCities"
          :class="{'is-invalid': validateErrors.city_id}"
        >
          <a-select-option v-for="city in cities" :key="city.id" :value="city.id" :label="city.title">
            {{city.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.city_id"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Адрес отправителя <span style="color: #e55353">*</span></label>
        <template>
          <a-input v-model="item.address" placeholder="Адрес отправителя" :class="{'is-invalid': validateErrors.address}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.address"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Тип здания <span style="color: #e55353">*</span></label>
        <a-select
            placeholder="Тип здания"
            style="width: 100%"
            v-model="item.type_of_building"
            optionLabelProp="label"
            optionFilterProp="label"
            :default-value="'residential'"
            :allowClear="true"
            :class="{'is-invalid': validateErrors.type_of_building}"
          >
          <a-select-option v-for="type in typeOfAddress" :key="type.value" :value="type.value" :label="type.title">
            {{type.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.type_of_building"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Ближайший ориентир</label>
        <template>
          <a-input placeholder="Ближайший ориентир" v-model="item.reference_point" :class="{'is-invalid': validateErrors.reference_point}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.reference_point"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Главный телефонный номер <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Главный телефонный номер" v-model="item.phone" :class="{'is-invalid': validateErrors.phone}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.phone"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Альтернативный тел.номер - 1</label>
        <template>
          <a-input placeholder="Альтернативный тел.номер - 1" v-model="item.phone_1" :class="{'is-invalid': validateErrors.phone_1}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.phone_1"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Альтернативный тел.номер - 2</label>
        <template>
          <a-input placeholder="Альтернативный тел.номер - 2" v-model="item.phone_2" :class="{'is-invalid': validateErrors.phone_2}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.phone_2"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Электронная почта отправителя</label>
        <template>
          <a-input placeholder="Электронная почта отправителя" v-model="item.email" :class="{'is-invalid': validateErrors.email}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.email"> {{value}} </div>
        </template>
      </div>

      <div class="d-flex mt-3">
        <button type="submit" class="ml-auto btn btn-primary" @click="updateItem">Сохранить</button>
      </div>
    </a-modal>

    <!-- Delete modal -->
    <a-modal v-model="visibleDeleteModal" title="Подтверждение на удаление" ok-text="Удалить" cancel-text="Отменить" @ok="destroyItem">
      <h6 style="color: red">Точно хотите безвозвратно удалить ?</h6>
    </a-modal>

    <!-- Map for Create-->
    <a-modal v-model="visibleMap" title="Геолокация" class="map__modal" @ok="visibleMap = false">
      <gmap-map
        :center="center"
        :zoom="10"
        style="width:100%;  height: 450px;"
        @click="addMarker"
        >
        <gmap-marker
          :position="location"
          @click="center=location"
        ></gmap-marker>
      </gmap-map>
      <div class="lat-long-block">
        <template>
          <a-input placeholder="Долгота и широта" v-model="newItem.points"
          />
        </template>
      </div>
    </a-modal>

    <!-- Map for Edit -->
    <a-modal v-model="visibleMapForEdit" title="Геолокация" class="map__modal" @ok="visibleMapForEdit = false">
      <gmap-map
        :center="centerForEdit"
        :zoom="10"
        style="width:100%;  height: 450px;"
        @click="addMarkerForEdit"
        >
        <gmap-marker
          :position="locationForEdit"
          @click="centerForEdit=locationForEdit"
        ></gmap-marker>
      </gmap-map>
      <div class="lat-long-block">
        <template>
          <a-input placeholder="Долгота и широта" v-model="item.points"
          />
        </template>
      </div>
    </a-modal>

  </div>
</template>

<script>
import axios from 'axios'
const data = '';
export default {
  name: "Clients",
  data() {
    return {
      index: null,
      data,
      item: [],
      newItem: {
        user_id: null,
        name: '',
        country_id: null,
        region_id: null,
        city_id: null,
        address: '',
        type_of_building: '',
        reference_point: '',
        phone: '',
        phone_1: '',
        phone_2: '',
        email: '',
        points: '',
      },
      oldItem: {
        user_id: null,
        name: '',
        country_id: null,
        region_id: null,
        city_id: null,
        address: '',
        type_of_building: '',
        reference_point: '',
        phone: '',
        phone_1: '',
        phone_2: '',
        email: '',
        points: '',
      },
      dataFilter: {
        clients: [],
        countries: [],
        regions: [],
        cityies: [],
        name: '',
        page: 1
      },
      validateErrors: [],

      countries: [{id: 192, title: 'Узбекистан'}],
      regions: [],
      cities: [],
      typeOfAddress: [{title: "Жилое", value: 'residential'},{title: "Коммерческое", value: 'commercial'}],
      clients: [],

      index: '',
      currentPage: 1,
      totalResults: 0,
      scrolled: false,
      old_height: 0,
      last_page: 1,

      load: true,
      visibleShowModal: false,
      visibleEditModal: false,
      visibleCreateModal: false,
      visibleDeleteModal: false,

      visibleMap: false,
      center: {
          lat: 41.31087153905742,
          lng: 69.27891510052102
      },
      location: {
        lat: '',
        lng: ''
      },

      visibleMapForEdit: false,
      centerForEdit: {
          lat: 41.31087153905742,
          lng: 69.27891510052102
      },
      locationForEdit: {
        lat: '',
        lng: ''
      },

    }
  },
  mounted(){

    this.getAllAddresses();


    this.getClients();

    window.onscroll = () => {
      let height = document.getElementById('addressesList').clientHeight
      if ((window.innerHeight + document.documentElement.scrollTop) >= height) {
        if(this.old_height!=height){
          this.old_height = height;
          let limit = this.currentPage + 1;
          if(limit <= this.last_page){
            console.log(limit);
            console.log(this.last_page);
            this.dataFilter.page = limit;
            this.addAddresses();
          }
        }
      }
    };

  },
  methods: {
    addAddresses(){
      this.load = true;
      axios.get('/clients/addresses/all', {params: this.dataFilter }).then((response) => {
        console.log(response.data);
        if(response.status == 200){
          this.data = this.data.concat(response.data.data);
          this.totalResults = response.data.total;
          this.last_page = response.data.last_page;
          this.currentPage = response.data.current_page;
        }else if(response.status == 204){
          this.$message.error(
            "Нет соответствующих адресов",
            3
          )
        }
      }).catch((error) => {

      }).finally(()=>{
        this.load = false;
      });
    },
    getAllAddresses(){
      this.dataFilter.page = 1;
      this.old_height = 0;
      axios.get('/clients/addresses/all', {params: this.dataFilter }).then((response) => {
        if(response.status == 200){
          this.data = response.data.data;
          this.totalResults = response.data.total;
          this.last_page = response.data.last_page;
          this.currentPage = response.data.current_page;
          console.log(response.data);
        }else if(response.status == 204){
          this.data = [];
          this.totalResults = 0;
          this.last_page = 0;
          this.currentPage = 0;
          this.$message.error(
            "Нет соответствующих адресов",
            3
          )
        }
      }).catch((error) => {

      }).finally(()=>{
        this.load = false;
      });
    },

    createItem(){
      this.visibleCreateModal = true;
    },
    editItem(value){
      this.visibleEditModal = true;
      this.item = value;
      this.validateErrors = [];
      let myPoints = this.item.points.split(",");


      this.locationForEdit.lat = parseFloat(myPoints[0]);
      this.locationForEdit.lng = parseFloat(myPoints[1]);

      this.$forceUpdate();

      console.log(this.locationForEdit);
    },
    showItem(value){
      this.visibleShowModal = true;
      this.item = value;
    },
    deleteItem(index, value){
      this.item = value;
      this.index = index;
      this.visibleDeleteModal = true;
    },

    storeItem(){
        this.load = true;
        axios.post('/clients/addresses', this.newItem).then((response) => {
          if(response.status == 201){ console.log(response.data);
            this.data = this.data.concat(response.data);
            this.totalResults++;
            this.newItem = this.oldItem;
            this.validateErrors = [];
            this.visibleCreateModal = false;
            this.$message.success(
              "Успешно добавлено",
              3
            );

          }
        }).catch((error) => {
          console.log(error.response.data);
          if(error.response.status == 422){
            this.$message.error(
              error.response.data.message,
              3
            );
            this.validateErrors = error.response.data.errors
          }else if(error.response.status == 404){
            this.$message.error(
              error.response.data.error,
              3
            );
          }
        }).finally(() => {
          this.load = false;
        })
    },
    updateItem(){
      this.load = true;
      axios.put('/clients/addresses/'+this.item.id, this.item).then((response) => {
        if(response.status == 200){
          this.$message.success(
            "Успешно изменено",
            3
          );
          this.validateErrors = [];
          this.visibleEditModal = false;
        }
      }).catch((error) => {
        if(error.response.status == 422){
          this.$message.error(
            error.response.data.message,
            3
          );
          this.validateErrors = error.response.data.errors;
        }else if(error.response.status == 404){
          this.$message.error(
            error.response.data.error,
            3
          )
        }else if(error.response.status == 500){
          this.$message.error(
            "Ошибка сервера",
            3
          );
        }
      }).finally(() => {
        this.load = false;
      });
    },
    destroyItem(){
      this.load = true;
      axios.delete('/clients/addresses/'+this.item.id).then((response) => {
        if(response.status == 200){
          this.$message.success(
            response.data.success,
            3
          );
          this.data.splice(this.index, 1)
          this.totalResults--;
          this.visibleDeleteModal = false;
        }
      }).catch((error) => {
        if(error.response.status == 404){
          this.$message.error(
            error.response.data.error,
            3
          );
        }else if(error.response.status == 500){
          this.$message.error(
            "Ошибка сервера",
            3
          );
        }
      }).finally(() => {
        this.load = false;
      });
    },


    getClients(){
      axios.get('/employee/clients').then(response => {
        if(response.status == 200){
          this.clients = response.data
        }else{
          this.$message.error(
            'Клиенты не найдено',
            3
          );
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else{
          this.$message.error(
            'Клиенты не найдено',
            3
          );
        }
      }).finally(() => {
        this.load = false;
      });
    },

    searchCountries(value){
      axios.get('/employee/countries/search', {params: {query: value}}).then((response) =>  {
        if(response.status == 200){
          this.countries = response.data;
        }else if(response.status == 204){
          this.$message.error(
            'Нет соответствующих стран',
            3
          );
        }
      }).catch((error)  =>  {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }
      }).finally(() => {

      });
    },
    changeCountries(value){
      axios.get('/employee/regions-with-cities/'+value).then((response) => {
        if(response.status == 200){
          this.regions = response.data.regions;
          this.cities = response.data.cities;
        }
      }).catch((error) => {

      });
    },
    searchRegions(value){
      axios.get('/employee/regions/search', {params: {query: value, country: this.dataFilterSender.country}}).then((response) => {
        if(response.status == 200){
          this.regions = response.data;
        }else if(response.status == 204){
          this.$message.error(
            'Нет соответствующих областей',
            3
          );
        }
      }).catch((error) => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }
      })
    },
    changeRegions(value){
      axios.get('/employee/cities-by-region/' + value).then((response) => {
        if(response.status == 200){
          this.cities = response.data.cities;
        }
      }).catch((error) => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }
      });
    },
    searchCities(value){
      axios.get('/employee/cities/search', {params: {query: value, country: this.dataFilterSender.country, region: this.dataFilterSender.region}}).then((response) => {
        if(response.status == 200){
          this.cities = response.data
        }else if(response.status == 204){
          this.$message.error(
            'Нет соответствующих районов',
            3
          );
        }
      }).catch((error)  =>  {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          )
        }
      })
    },
    changeCities(value){

    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

    applyFilter(){
      this.load = true;
      this.getAllAddresses();
    },
    applyFilterAutocomplete(){
      if(this.itemName.length > 1 || this.itemName.length == 0){
        this.old_height = 0;
        this.load = true;
        axios.post('/branches/international/autocomplete/search/with/paginate', this.dataFilter, {params: {query: this.itemName}})
        .then(response => {
          if(response.status == 200){
            this.data = response.data.data;
            this.totalResults = response.data.total;
            this.load = false;
            this.last_page = response.data.last_page;
          }else if(response.status == 204){
            this.data = [];
            this.totalResults = 0;
            this.load = false;
            this.$message.error(
              'Офисов не найдено',
              3
            );
          }
        }).catch(error => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
            this.load = false;
          }else if(error.response.status == 204){
            this.data = [];
            this.totalResults = 0;
            this.load = false;
            this.$message.error(
              'Офисов не найдено',
              3
            );
          }else{
            this.load = false
          }
        });
      }
    },
    searchCountry(value){
      if(value.length>1){
        axios.get('/international/countries/autocomplete/search', {params: {query: value}}).then(response => {
          if(response.status == 200){
            this.countries = response.data;
          }else if(response.status == 204){
            this.$message.error(
                  'Нет соответствующих стран',
                  3,
            );
          }
        }).catch(error => {
          if(error.response.status == 204){
            this.$message.error(
                  'Нет соответствующих стран',
                  3,
            );
          }else if(error.response.status == 500){
            this.$message.error(
                  'Ошибка сервера',
                  3,
            );
          }
        })
      }
    },
    searchRegion(value){
      if(value.length>1){
        axios.post('/international/regions/autocomplete/search', this.dataFilter, {params: {query: value}}).then(response => {
          if(response.status == 200){
            this.regions = response.data
          }else if(response.status == 204){
            this.$message.error(
                  'Нет соответствующих регионов',
                  3,
            );
          }
        }).catch(error => {
          if(error.response.status == 204){
            this.$message.error(
                  'Нет соответствующих регионов',
                  3,
            );
          }else if(error.response.status == 500){
            this.$message.error(
                  'Ошибка сервера',
                  3,
            );
          }
        })
      }
    },
    searchCity(value){
      if(value.length>1){
        axios.post('/international/cities/autocomplete/search', this.dataFilter, {params: {query: value}}).then(response => {
          if(response.status == 200){
            this.cities = response.data
          }else if(response.status == 204){
            this.$message.error(
                  'Нет соответствующих городов',
                  3,
            );
          }
        }).catch(error => {
          if(error.response.status == 204){
            this.$message.error(
                  'Нет соответствующих городов',
                  3,
            );
          }else if(error.response.status == 500){
            this.$message.error(
                  'Ошибка сервера',
                  3,
            );
          }
        });
      }
    },

    addMarker(event) {
       this.location.lat = event.latLng.lat();
       this.location.lng = event.latLng.lng();
    },
    addMarkerForEdit(event){
      this.locationForEdit.lat = event.latLng.lat();
      this.locationForEdit.lng = event.latLng.lng();
    }

  },
  computed: {
    me () {
      return JSON.parse(localStorage.me)
    }
  },
  watch: {
    "visibleMap": function(value){
      if(value){
        setTimeout(() => {
          this.driving = "DRIVING";
        }, 1000);
      }else{
        this.driving = "WALKING";
      }
    },
    "location.lng": function(value){
      let point = this.location.lat + ','+ this.location.lng;
      this.newItem.points = point;
      console.log(this.newItem);
    },

    "visibleMapForEdit": function(value){
      if(value){
        setTimeout(() => {
          this.driving = "DRIVING";
        }, 1000);
      }else{
        this.driving = "WALKING";
      }
    },
    "locationForEdit.lng": function(value){
      let point = this.locationForEdit.lat + ','+ this.locationForEdit.lng;
      this.item.points = point;
      console.log(this.item);
    },
  }
}
</script>

<style>
  .ant-modal-mask{
    z-index: 2000 !important;
  }
  .ant-modal-wrap{
    z-index: 2000 !important;
  }
  .ant-message{
    z-index: 2010 !important
  }
  .ant-message-notice{
    z-index: 2020 !important;
  }
  .ant-message-notice-content{
    z-index: 2020 !important;
  }
  .ant-select-dropdown{
    z-index: 2000 !important;
  }
  .address-create .ant-modal{
    width: 600px !important;
    height: 100%;
    top: 10px;
  }
  .map__modal .ant-modal{
    width: 1000px !important;
    height: 500px !important;
    top: 10px;
  }

  .my-img-circle{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #e9e9e9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .my-img-circle img{
    width: 75px;
  }




  .dropZone {
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px dotted #2e94c4;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #5C5C5C;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>
